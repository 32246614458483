import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//import OnboardLayout from "./layouts/Onboard";
//import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
//import ProtectedPage from "./pages/protected/ProtectedPage";
import Users from "./pages/pages/Users";
import Roads from "./pages/pages/Roads";
import Properties from "./pages/pages/Properties";
import Map from "./pages/pages/Map";
//import Onboarding from "./components/auth/RoadRegister";
import Ledger from "./pages/pages/accounting/Ledger";
import Journal from "./pages/pages/accounting/Journal";
import FinancialStatement from "./pages/pages/accounting/FinancialStatement";
import Docs from "./pages/pages/Docs";
import FiscalYear from "./pages/pages/accounting/FiscalYear";
import Voucher from "./pages/pages/accounting/Voucher";
import Units from "./pages/pages/unitization/Units";
import Contact from "./pages/pages/Contact";
import Accosiation from "./pages/pages/Association";
import Activate from "./pages/auth/Activate";
import Files from "./pages/pages/Files";
import Invites from "./pages/pages/meeting/Invites";
import Presentation from "./layouts/Presentation";
import FAQ from "./pages/presentation/Landing/FAQ";
import Terms from "./pages/presentation/Landing/Terms";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

// Form components
// const Pickers = async(() => import("./pages/forms/Pickers"));
// const Editors = async(() => import("./pages/forms/Editors"));
// const Formik = async(() => import("./pages/forms/Formik"));

// // Icon components
// const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));

// // Table components
// const DataGrid = async(() => import("./pages/tables/DataGrid"));

// // Chart components
// const Chartjs = async(() => import("./pages/charts/Chartjs"));
// const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// // Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "accosiations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Accosiation />,
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Users />,
      },
    ],
  },
  {
    path: "roads",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Roads />,
      },
    ],
  },
  {
    path: "properties",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Properties />,
      },
    ],
  },
  {
    path: "accounting",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "fiscal-years",
        element: <FiscalYear />,
      },
      {
        path: "ledger",
        element: <Ledger />,
      },
      {
        path: "journal",
        element: <Journal />,
      },
      {
        path: "financial-statement",
        element: <FinancialStatement />,
      },
      {
        path: "vouchers",
        element: <Voucher />,
      },
    ],
  },
  {
    path: "units",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <Units />,
      },
    ],
  },
  {
    path: "messages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Map />,
      },
    ],
  },
  {
    path: "meeting",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "invites",
        element: <Invites />,
      },
    ],
  },
  {
    path: "files",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Files />,
      },
    ],
  },
  {
    path: "map",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Map />,
      },
    ],
  },
  {
    path: "docs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Docs />,
      },
    ],
  },
  {
    path: "contact",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Contact />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "register",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "activate",
        element: <Activate />,
      },
    ],
  },
  // {
  //   path: "",
  //   element: (
  //     <AuthGuard>
  //       <OnboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "onboarding",
  //       element: <Onboarding />,
  //     },
  //   ],
  // },
  {
    path: "",
    element: <Presentation />,
    children: [
      {
        path: "terms",
        element: <Terms />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
