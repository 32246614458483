import {
  AccountBalance,
  Chat,
  Folder,
  Groups,
  Help,
} from "@mui/icons-material";
import { SidebarItemsType } from "../../types/sidebar";

import {
  Map,
  Users,
  Home,
  Truck,
  BarChart,
  Sliders,
  Mail,
  Divide,
} from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Etusivu",
    disabled: false,
    alt: "text",
  },
  {
    href: "/accosiations",
    icon: AccountBalance,
    title: "Hoitokunta",
    disabled: false,
    alt: "text",
  },
  {
    href: "/users",
    icon: Users,
    title: "Osakkaat",
    disabled: false,
    alt: "text",
  },
  {
    href: "/roads",
    icon: Truck,
    title: "Tiet",
    disabled: false,
    alt: "text",
  },
  {
    href: "/properties",
    icon: Home,
    title: "Kiinteistöt",
    disabled: false,
    alt: "text",
  },
  {
    href: "/accounting",
    icon: BarChart,
    title: "Talous",
    disabled: false,
    alt: "text",
    children: [
      {
        href: "/accounting/fiscal-years",
        title: "Tilikaudet",
      },
      {
        href: "/accounting/ledger",
        title: "Pääkirja",
      },
      {
        href: "/accounting/journal",
        title: "Päiväkirja",
      },
      {
        href: "/accounting/financial-statement",
        title: "Tulos & Tase",
      },
      {
        href: "/accounting/vouchers",
        title: "Tositteet",
      },
    ],
  },
  {
    href: "/units",
    icon: Divide,
    title: "Yksiköinti",
    disabled: false,
    alt: "text",
    children: [
      {
        href: "/units/list",
        title: "Maksuunpanoluettelo",
      },
    ],
  },
  {
    href: "/messages",
    icon: Mail,
    title: "Viestit",
    disabled: true,
    alt: "text",
  },
  {
    href: "/meeting",
    icon: Groups,
    title: "Kokous",
    disabled: true,
    alt: "text",
    children: [
      {
        href: "/meeting/invites",
        title: "Kokouskutsut",
      },
    ],
  },
  {
    href: "/files",
    icon: Folder,
    title: "Tiedostot",
    disabled: false,
    alt: "text",
  },
  {
    href: "/map",
    icon: Map,
    title: "Kartta",
    disabled: false,
    alt: "text",
  },
  {
    href: "/docs",
    icon: Help,
    title: "Ohjeet",
    disabled: false,
    alt: "text",
  },
  {
    href: "/contact",
    icon: Chat,
    title: "Yhteys",
    disabled: false,
    alt: "text",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Sivut",
    pages: pagesSection,
  },
];

export default navItems;
