import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import { baseUrl } from "../../../config";
import { fetchLedger } from "../../../redux/slices/accounting";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAuth from "../../../hooks/useAuth";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Ledger(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { ledger } = useAppSelector((state) => state.accounting);

  const [expandedAccounts, setExpandedAccounts] = useState<any>({});

  // Toggle function for expanding/collapsing transactions
  const toggleExpand = (accountCode: any) => {
    setExpandedAccounts((prev: any) => ({
      ...prev,
      [accountCode]: !prev[accountCode],
    }));
  };

  useEffect(() => {
    dispatch(
      fetchLedger({
        associationId: currentUser?.associations[0].id,
        from: "2020-01-01",
        to: "2024-12-31",
      })
    );
  }, [dispatch, currentUser?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Pääkirja
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Pääkirja</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Pääkirja
              </Typography>

              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Tili</TableCell>
                    <TableCell>Kuvaus</TableCell>
                    <TableCell align="right">Debet</TableCell>
                    <TableCell align="right">Kredit</TableCell>
                    <TableCell>Pvm</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ledger.map((account: any) => (
                    <React.Fragment key={account.accountCode}>
                      {/* Parent Row with Toggle Arrow */}
                      <TableRow>
                        <TableCell colSpan={5}>
                          <IconButton
                            onClick={() => toggleExpand(account.accountCode)}
                          >
                            {expandedAccounts[account.accountCode] ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", display: "inline" }}
                          >
                            Account: {account.accountCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {/* Conditionally Render Transaction Rows */}
                      {expandedAccounts[account.accountCode] &&
                        account.transactions.map((transaction: any) => (
                          <TableRow key={transaction.id}>
                            <TableCell>{account.accountCode}</TableCell>
                            <TableCell>{transaction.description}</TableCell>
                            <TableCell align="right">
                              {transaction.debit}
                            </TableCell>
                            <TableCell align="right">
                              {transaction.credit}
                            </TableCell>
                            <TableCell>
                              {format(new Date(transaction.date), "dd-MM-yyyy")}
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Ledger;
