import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import * as turf from "@turf/turf";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppSelector from "../../hooks/useAppSelector";
import { fetchRoads } from "../../redux/slices/road";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const paragraphStyle = {
  fontFamily: "Open Sans",
  margin: 0,
  fontSize: 13,
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || "";

// types.ts
// interface Road {
//   id: string;
//   location: {
//     coordinates: [number, number];
//   };
//   geom: GeoJSON.Geometry;
// }

// interface MapComponentProps {
//   roads: Road[];
// }

interface DrawingMetrics {
  area: number;
  lineLength: number;
}

// constants.ts
const MAPBOX_CONSTANTS = {
  STYLE_URL: "mapbox://styles/mapbox/outdoors-v11",
  INITIAL_ZOOM: 11,
  MML_TILE_URL:
    "https://avoin-karttakuva.maanmittauslaitos.fi/kiinteisto-avoin/tiles/wmts/1.0.0/kiinteistojaotus/default/v3/WGS84_Pseudo-Mercator/{z}/{y}/{x}.pbf?api-key=e73cf967-d0e5-4150-88f0-931689fcbe82",
} as const;

// hooks/useMapDrawing.ts
const useMapDrawing = (map: mapboxgl.Map | null) => {
  const [metrics, setMetrics] = useState<DrawingMetrics>({
    area: 0,
    lineLength: 0,
  });

  const initializeDrawing = useCallback(() => {
    if (!map) return;

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        line_string: true,
        polygon: true,
        trash: true,
      },
    });

    map.addControl(draw);

    const updateArea = (e: any) => {
      const data = draw.getAll();
      if (data.features.length > 0) {
        const area = turf.area(data);
        setMetrics((prev) => ({ ...prev, area: Math.round(area * 100) / 100 }));
      } else {
        setMetrics((prev) => ({ ...prev, area: 0 }));
        if (e.type !== "draw.delete")
          alert("Piirrä monikulmio klikkaamalla karttaa");
      }
    };

    const updateLineLength = (e: any) => {
      const data = draw.getAll();
      if (data.features.length > 0) {
        const length = turf.length(data, { units: "meters" });
        setMetrics((prev) => ({ ...prev, lineLength: length }));
      } else {
        setMetrics((prev) => ({ ...prev, lineLength: 0 }));
        if (e.type !== "draw.delete")
          alert("Piirrä viiva klikkaamalla karttaa");
      }
    };

    const updateDrawing = (e: any) => {
      if (draw.getMode() === "draw_polygon") {
        updateArea(e);
      }
      if (draw.getMode() === "draw_line_string") {
        updateLineLength(e);
      }
    };

    map.on("draw.create", updateDrawing);
    map.on("draw.delete", updateDrawing);
    map.on("draw.update", updateDrawing);

    return () => {
      map.removeControl(draw);
    };
  }, [map]);

  return { metrics, initializeDrawing };
};

// components/MapMetrics.tsx
const MetricsDisplay: React.FC<{ metrics: DrawingMetrics }> = ({ metrics }) => (
  <div id="calculated-area">
    {metrics.area > 0 && (
      <p style={paragraphStyle}>
        <strong>{metrics.area}</strong> m2
      </p>
    )}
    {metrics.lineLength > 0 && (
      <p style={paragraphStyle}>
        <strong>{metrics.lineLength}</strong> metriä
      </p>
    )}
  </div>
);

const MapComponent: React.FC<any> = ({ roads }) => {
  const mapDiv = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [center, setCenter] = useState<[number, number]>([0, 0]);
  const { metrics, initializeDrawing } = useMapDrawing(map);

  useEffect(() => {
    if (roads?.length > 0) {
      setCenter(roads[0].location.coordinates);
    }
  }, [roads]);

  useEffect(() => {
    if (!mapDiv.current || map) return;

    const newMap = new mapboxgl.Map({
      container: mapDiv.current,
      style: MAPBOX_CONSTANTS.STYLE_URL,
      center,
      zoom: MAPBOX_CONSTANTS.INITIAL_ZOOM,
    });

    newMap.on("load", () => {
      // Add MML vector tiles
      newMap.addSource("pbfTiles", {
        type: "vector",
        tiles: [MAPBOX_CONSTANTS.MML_TILE_URL],
        minzoom: 10,
        maxzoom: 16,
      });

      // Add property boundaries
      newMap.addLayer({
        id: "kiinteistorajat",
        type: "line",
        source: "pbfTiles",
        "source-layer": "KiinteistorajanSijaintitiedot",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#888",
          "line-width": 2,
        },
      });

      // Add boundary markers
      newMap.addLayer({
        id: "rajamerkki",
        type: "circle",
        source: "pbfTiles",
        "source-layer": "RajamerkinSijaintitiedot",
        paint: {
          "circle-radius": 3,
          "circle-color": "red",
        },
      });

      // Add property IDs
      newMap.addLayer({
        id: "text-layer",
        type: "symbol",
        source: "pbfTiles",
        "source-layer": "KiinteistotunnuksenSijaintitiedot",
        layout: {
          "text-field": ["get", "kiinteistotunnuksenEsitysmuoto"],
          "text-size": 12,
          "text-anchor": "top",
          "text-offset": [0, 1],
        },
        paint: {
          "text-color": "#000000",
        },
      });

      // Add roads
      roads.forEach((road: any, index: any) => {
        newMap.addSource(`route${index}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: road.geom,
          },
        });

        newMap.addLayer({
          id: `route${index}`,
          type: "line",
          source: `route${index}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "blue",
            "line-width": 2,
          },
        });
      });

      setMap(newMap);
    });

    return () => {
      newMap.remove();
    };
  }, [center, roads]);

  useEffect(() => {
    if (map) {
      initializeDrawing();
    }
  }, [map, initializeDrawing]);

  return (
    <Card mb={6}>
      <div>
        <div
          style={{ width: "100%", height: "650px" }}
          ref={mapDiv}
          className="map-container"
        />
      </div>
      <MetricsDisplay metrics={metrics} />
    </Card>
  );
};

const MapPage: React.FC = () => {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { roads } = useAppSelector((state) => state.road);

  useEffect(() => {
    if (currentUser?.associations?.[0]?.id) {
      dispatch(fetchRoads(currentUser.associations[0].id));
    }
  }, [dispatch, currentUser]);

  return (
    <>
      <Helmet title="Kartta" />
      <Typography variant="h3" gutterBottom display="inline">
        Kartta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Kartta</Typography>
      </Breadcrumbs>

      <Divider sx={{ my: 6 }} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MapComponent roads={roads} />
        </Grid>
      </Grid>
    </>
  );
};

export default MapPage;
