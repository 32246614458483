import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  CardContent as MuiCardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  createVoucher,
  deleteVoucher,
  fetchVouchers,
  updateVoucher,
} from "../../../redux/slices/accounting";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAuth from "../../../hooks/useAuth";
import { Add, Delete, Edit, Remove, Search } from "@mui/icons-material";
import { format } from "date-fns";
import { Field, FieldArray, Form, Formik } from "formik";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DatePicker = styled(MuiDatePicker)<{ my?: number }>(spacing);

const accounts: any[] = [
  {
    code: 100,
    name: "Aineelliset",
    rowId: "1.1",
  },
  {
    code: 101,
    name: "Aineettomat",
    rowId: "1.1",
  },
  {
    code: 102,
    name: "Sijoitukset",
    rowId: "1.1",
  },
  {
    code: 150,
    name: "Vaihto-omaisuus",
    rowId: "1.2",
  },
  {
    code: 151,
    name: "Saamiset",
    rowId: "1.2",
  },
  {
    code: 160,
    name: "MP-lainasaaminen osakkailta",
    rowId: "1.2",
  },
  {
    code: 161,
    name: "Muut saamiset",
    rowId: "1.2",
  },
  {
    code: 170,
    name: "Siirtosaamiset",
    rowId: "1.2",
  },
  {
    code: 190,
    name: "Pankkitili",
    rowId: "1.2",
  },
  {
    code: 200,
    name: "Edell. tilik. voitto (tappio)",
    rowId: "2.1",
  },
  {
    code: 230,
    name: "Tilikauden voitto (tappio)",
    rowId: "2.1",
  },
  {
    code: 250,
    name: "MP-laina",
    rowId: "2.2.1",
  },
  {
    code: 260,
    name: "Siirtovelat",
    rowId: "2.2.2",
  },
  {
    code: 300,
    name: "Yksikkömaksut",
    rowId: "3.1.1.1",
  },
  {
    code: 310,
    name: "Perusmaksut",
    rowId: "3.1.1.1",
  },
  {
    code: 320,
    name: "Liittymismaksut",
    rowId: "3.1.1.1",
  },
  {
    code: 330,
    name: "Kuljetusmaksut",
    rowId: "3.1.1.1",
  },
  {
    code: 340,
    name: "Muut tuotot",
    rowId: "3.1.1.1",
  },
  {
    code: 350,
    name: "Viivästysmaksut",
    rowId: "3.1.1.1",
  },
  {
    code: 400,
    name: "Lanaus",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 410,
    name: "Sora, murske",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 420,
    name: "Pölynsidonta",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 430,
    name: "Vesakontorjunta, niitto",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 440,
    name: "Muut kesätyöt",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 450,
    name: "Tarvikkeet kesällä",
    rowId: "3.1.1.2.1.1",
  },
  {
    code: 460,
    name: "Ojat",
    rowId: "3.1.1.2.1.2",
  },
  {
    code: 470,
    name: "Rummut",
    rowId: "3.1.1.2.1.2",
  },
  {
    code: 480,
    name: "Muut korjaukset",
    rowId: "3.1.1.2.1.2",
  },
  {
    code: 500,
    name: "Auraus",
    rowId: "3.1.1.2.1.3",
  },
  {
    code: 510,
    name: "Hiekoitus",
    rowId: "3.1.1.2.1.3",
  },
  {
    code: 520,
    name: "Aurausviitat",
    rowId: "3.1.1.2.1.3",
  },
  {
    code: 530,
    name: "Muut talvityöt",
    rowId: "3.1.1.2.1.3",
  },
  {
    code: 540,
    name: "Tarvikkeet talvella",
    rowId: "3.1.1.2.1.3",
  },
  {
    code: 600,
    name: "Kokouskulut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 610,
    name: "Palkat ja palkkiot",
    rowId: "3.1.1.2.2",
  },
  {
    code: 620,
    name: "Kirjanpito",
    rowId: "3.1.1.2.2",
  },
  {
    code: 630,
    name: "Matkakulut, päivärahat",
    rowId: "3.1.1.2.2",
  },
  {
    code: 640,
    name: "Postikulut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 650,
    name: "Pankkikulut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 660,
    name: "Muut hallintokulut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 670,
    name: "Jäsenmaksut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 680,
    name: "Ulosottokulut",
    rowId: "3.1.1.2.2",
  },
  {
    code: 690,
    name: "Vakuutukset",
    rowId: "3.1.1.2.2",
  },
  {
    code: 700,
    name: "Korkotulot",
    rowId: "3.1.2",
  },
  {
    code: 800,
    name: "Satunnaiset tuotot",
    rowId: "3.2",
  },
  {
    code: 850,
    name: "Satunnaiset menot",
    rowId: "3.2",
  },
  {
    code: 900,
    name: "Avustukset kunnalta",
    rowId: "3.3",
  },
  {
    code: 910,
    name: "Avustukset valtiolta",
    rowId: "3.3",
  },
  {
    code: 999,
    name: "Tilikauden voitto (tappio)",
    rowId: "3",
  },
];

const initialValues = {
  description: "",
  date: null,
  transactions: [{ description: "", debit: "", credit: "", accountCode: "" }],
};

function VoucherForm(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const voucher = useAppSelector((state) =>
    state.accounting.vouchers.find((voucher) => voucher.id === props.voucherId)
  );

  const { mode } = props;

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (voucher && voucher.id !== undefined) {
        dispatch(updateVoucher(voucher.id, values));
      } else {
        dispatch(createVoucher(values, currentUser?.associations[0].id));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);

      props.handleMode(null);
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        voucher
          ? {
              description: voucher.description,
              date: voucher.date,
              transactions: voucher.transactions,
            }
          : {
              description: "",
              date: null,
              transactions: [
                { description: "", debit: "", credit: "", accountCode: "" },
              ],
            }
      }
      //validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Card mb={6}>
          <CardContent>
            <Form>
              <Typography variant="h5" gutterBottom>
                Tosite
              </Typography>

              {/* Voucher Details */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="description"
                    as={TextField}
                    label="Nimi"
                    fullWidth
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    disabled={mode === "read"}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    label="Pvm"
                    value={values.date}
                    onChange={(date) => setFieldValue("date", date)}
                    disabled={mode === "read"}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        error={touched.date && Boolean(errors.date)}
                        //helperText={touched.date && errors.date}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Tapahtumat
              </Typography>

              <FieldArray name="transactions">
                {({ push, remove }) => (
                  <div>
                    {values.transactions.map((transaction: any, index: any) => (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <Field
                            name={`transactions[${index}].description`}
                            as={TextField}
                            label="Kuvaus"
                            fullWidth
                            // error={
                            //   touched.transactions?.[index]?.description &&
                            //   Boolean(errors.transactions?.[index]?.description)
                            // }
                            // helperText={
                            //   touched.transactions?.[index]?.description &&
                            //   errors.transactions?.[index]?.description
                            // }
                            disabled={mode === "read"}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Field
                            name={`transactions[${index}].debit`}
                            as={TextField}
                            label="Debet"
                            fullWidth
                            // error={
                            //   touched.transactions?.[index]?.debet &&
                            //   Boolean(errors.transactions?.[index]?.debet)
                            // }
                            // helperText={
                            //   touched.transactions?.[index]?.debet &&
                            //   errors.transactions?.[index]?.debet
                            // }
                            disabled={mode === "read"}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Field
                            name={`transactions[${index}].credit`}
                            as={TextField}
                            label="Credit"
                            fullWidth
                            // error={
                            //   touched.transactions?.[index]?.credit &&
                            //   Boolean(errors.transactions?.[index]?.credit)
                            // }
                            // helperText={
                            //   touched.transactions?.[index]?.credit &&
                            //   errors.transactions?.[index]?.credit
                            // }
                            disabled={mode === "read"}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            name={`transactions[${index}].accountCode`}
                            as={TextField}
                            label="Tili"
                            fullWidth
                            select
                            // error={
                            //   touched.transactions?.[index]?.accountCode &&
                            //   Boolean(errors.transactions?.[index]?.accountCode)
                            // }
                            // helperText={
                            //   touched.transactions?.[index]?.accountCode &&
                            //   errors.transactions?.[index]?.accountCode
                            // }
                            disabled={mode === "read"}
                          >
                            {accounts.map((account) => (
                              <MenuItem key={account.code} value={account.code}>
                                {account.code + " - " + account.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        <Grid item xs={2}>
                          <IconButton
                            color="secondary"
                            onClick={() => remove(index)}
                            disabled={mode === "read"}
                          >
                            <Remove />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    {/* Add Transaction Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        push({
                          description: "",
                          debet: "",
                          credit: "",
                          accountCode: "",
                        })
                      }
                      startIcon={<Add />}
                      style={{ marginTop: "10px" }}
                      disabled={mode === "read"}
                    >
                      Lisää tapahtuma
                    </Button>
                  </div>
                )}
              </FieldArray>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: "32px", marginRight: "8px" }}
                disabled={mode === "read"}
                mt={3}
              >
                Lähetä
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{ marginTop: "32px" }}
                mt={3}
                onClick={() => props.handleMode(null)}
              >
                Peruuta
              </Button>
            </Form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function VoucherList(props: any) {
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tosittet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleMode("create")}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Kuvaus</TableCell>
              <TableCell>Pvm</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.id} hover>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {format(new Date(row.date), "dd-MM-yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleMode("read", row.id)}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleMode("edit", row.id)}
                  />{" "}
                  <Delete
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Voucher() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { vouchers } = useAppSelector((state) => state.accounting);

  const [mode, setMode] = useState<any | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    //dispatch(openRoadForm());
  };

  const handleMode = (mode: string, voucherId: number) => {
    if (mode === null) {
      setMode(null);
    } else {
      setMode({ mode, voucherId });
    }
  };

  const handleEditVoucher = (road: any) => {
    setOpen(!open);
    //dispatch(setRoad(road));
    //dispatch(openRoadForm());
  };

  const handleDelete = (voucher: any) => {
    // TODO confirm
    dispatch(deleteVoucher(voucher.id));
  };

  useEffect(() => {
    dispatch(
      fetchVouchers({
        associationId: currentUser?.associations[0].id,
      })
    );
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Tositteet" />
      <Typography variant="h3" gutterBottom display="inline">
        Tositteet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Tositteet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {mode !== null ? (
        <VoucherForm
          handleMode={handleMode}
          mode={mode.mode}
          voucherId={mode.voucherId}
        />
      ) : (
        <VoucherList
          rows={vouchers}
          handleMode={handleMode}
          mode={mode}
          //handleEditVoucher={handleEditVoucher}
          handleDelete={handleDelete}
          //openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Voucher;
