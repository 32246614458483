import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface Property {
  id?: number;
  name: string;
  code: string;
  address: string;
  user: any;
  propertyGeometries: any;
  units: number;
}

export interface PropertyState {
  property: Property | undefined;
  properties: Property[];
  open: boolean;
  editable: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: PropertyState = {
  property: undefined,
  properties: [],
  open: false,
  editable: false,
  status: "idle",
};

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/properties?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperty(state, action) {
      state.property = action.payload;
    },
    addProperty(state, action) {
      state.properties.push(action.payload);
    },
    updateProperty(state, action) {
      const propertyId = state.properties.findIndex(
        (r) => r.id === action.payload.id
      );
      state.properties[propertyId] = action.payload;
    },
    deleteProperty(state, action) {
      state.properties = state.properties.filter(
        (r: Property) => r.id !== action.payload
      );
    },
    setOpen(state) {
      state.open = !state.open;
    },
    setEditable(state) {
      state.editable = !state.editable;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      state.properties = action.payload;
    });
  },
});

export function setProperty(property: Property) {
  return async (dispatch: Dispatch) => {
    dispatch(propertySlice.actions.setProperty(property));
  };
}

export function createProperty(property: Property) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(`${baseUrl}/properties`, property);
    dispatch(propertySlice.actions.addProperty(response.data));
  };
}

export function updateProperty(id: number, property: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(`${baseUrl}/properties/${id}`, property);
    dispatch(propertySlice.actions.updateProperty(response.data));
  };
}

export function deleteProperty(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/properties/${id}`);
    dispatch(propertySlice.actions.deleteProperty(id));
  };
}

export function editPropertyForm() {
  return async (dispatch: Dispatch) => {
    dispatch(propertySlice.actions.setEditable());
  };
}

export function openPropertyForm() {
  return async (dispatch: Dispatch) => {
    dispatch(propertySlice.actions.setOpen());
  };
}

export default propertySlice.reducer;
