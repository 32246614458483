import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { Formik } from "formik";
import property, {
  updateProperty,
  createProperty,
  openPropertyForm,
  fetchProperties,
  setProperty,
  deleteProperty,
  editPropertyForm,
} from "../../redux/slices/property";
import road from "../../redux/slices/road";
import useAuth from "../../hooks/useAuth";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || "";

function Mappi(props: any) {
  const mapDiv = useRef<HTMLDivElement>(null);
  let [map, setMap] = useState(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [propertyFeature, setPropertyFeature] = useState<any>(undefined);
  const [zoom, setZoom] = useState(9);
  const [roundedArea, setRoundedArea] = useState<number>(0);
  const [lineLength, setLineLength] = useState<number>(0);

  useEffect(() => {
    if (props.propertyGeometries) {
      setLng(props.propertyGeometries[0].location.coordinates[0]);
      setLat(props.propertyGeometries[0].location.coordinates[1]);
      setPropertyFeature(props.propertyGeometries[0].geom);
    }
  }, [props.propertyGeometries]);

  useEffect(() => {
    const attachMap = (
      setMap: React.Dispatch<React.SetStateAction<any>>,
      mapDiv: React.RefObject<HTMLDivElement>
    ) => {
      if (!mapDiv.current) {
        return;
      }
      const map = new mapboxgl.Map({
        // container: mapDiv.current, // ERROR
        container: mapDiv.current || "", // NO ERROR
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [lng, lat],
        zoom: 13,
      });

      map.on("load", () => {
        // setMap(map);
        // map.resize();

        for (let i = 0; i < props.propertyGeometries.length; i++) {
          map.addSource("location" + i, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: props.propertyGeometries[i].location,
            },
          });

          map.addLayer({
            id: "location" + i,
            type: "circle",
            source: "location" + i,
            paint: {
              "circle-color": "red",
            },
          });

          map.addSource("property" + i, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: props.propertyGeometries[i].geom,
            },
          });

          map.addLayer({
            id: "property" + i,
            type: "line",
            source: "property" + i,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "blue",
              "line-width": 2,
            },
          });
        }

        setMap(map);
      });

      // setMap(map);
    };

    !map && attachMap(setMap, mapDiv);
  }, [map, lat, lng, propertyFeature]);

  return (
    <Card mb={6}>
      <div>
        <div
          style={{ width: "100%", height: "650px" }}
          ref={mapDiv}
          className="map-container"
        />
      </div>
    </Card>
  );
}

function PropertyForm(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { property } = useAppSelector((state) => state.property);
  const { users } = useAppSelector((state) => state.user);

  const [propertyType, setPropertyType] = useState("");
  const [propertyTraffic, setPropertyTraffic] = useState("");

  const handleRadioChange = (event: any) => {
    setPropertyType(event.target.value);
  };

  const handleTrafficRadioChange = (event: any) => {
    setPropertyTraffic(event.target.value);
  };

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (property && property.id !== undefined) {
        dispatch(updateProperty(property.id, values));
      } else {
        dispatch(createProperty(values));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      dispatch(openPropertyForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        property
          ? {
              name: property.name,
              code: property.code,
              address: property.address,
              user: property.user.id,
              road: currentUser?.associations[0].road.id,
              units: property.units,
            }
          : {
              name: "",
              code: "",
              address: "",
              user: "",
              road: currentUser?.associations[0].road.id,
              units: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Kiinteistöt
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    required
                    name="name"
                    label="Nimi"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    name="code"
                    label="Kiinteistötunnus"
                    placeholder="999-999-9-99"
                    value={values.code}
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    name="address"
                    label="Osoite"
                    value={values.address}
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    select
                    name="user"
                    label="Osakas"
                    value={values.user}
                    error={Boolean(touched.user && errors.user)}
                    fullWidth
                    //helperText={touched.user && errors.user}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    my={2}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName + " " + user.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Yksiköinti
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      //value={propertyType}
                      //onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="easy"
                        control={<Radio />}
                        label="Helppo"
                        checked
                      />
                      <FormControlLabel
                        value="advanced"
                        control={<Radio />}
                        label="Edistynyt"
                        disabled
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    required
                    name="units"
                    label="Yksiköt"
                    placeholder="1000"
                    value={values.units}
                    error={Boolean(touched.units && errors.units)}
                    fullWidth
                    helperText={touched.units && errors.units}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>

            {property && (
              <>
                <Typography variant="h6" gutterBottom mt={3} mb={3}>
                  Omistaja
                </Typography>

                <div>
                  {property?.user.firstName + " " + property?.user.lastName}
                </div>

                <Typography variant="h5" gutterBottom mt={3} mb={3}>
                  Yksiköinti
                </Typography>

                {/* <form>
                  <Typography variant="h6" gutterBottom mt={3} mb={3}>
                    Kiinteistö
                  </Typography>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Kiinteistön tyyppi
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={propertyType}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="house"
                        control={<Radio />}
                        label="Asunto"
                      />
                      <FormControlLabel
                        value="cottage"
                        control={<Radio />}
                        label="Vapaa-ajan asunto"
                      />
                      <FormControlLabel
                        value="farm"
                        control={<Radio />}
                        label="Maatila/Yritys"
                      />
                      <FormControlLabel
                        value="field"
                        control={<Radio />}
                        label="Pelto"
                      />
                      <FormControlLabel
                        value="forest"
                        control={<Radio />}
                        label="Metsä"
                      />
                    </RadioGroup>
                  </FormControl>

                  {propertyType === "house" && (
                    <div>
                      <FormControl>
                        <FormLabel>Matka (m)</FormLabel>
                        <TextField
                          id="outlined-basic"
                          //label="Outlined"
                          variant="outlined"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Korjauskerroin (%)</FormLabel>
                        <TextField
                          id="outlined-basic"
                          //label="Outlined"
                          variant="outlined"
                        />
                      </FormControl>
                    </div>
                  )}

                  {propertyType === "cottage" && (
                    <div>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Käyttötarkoitus
                        </FormLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select1"
                          value={1}
                          label="Age"
                          //onChange={handleChange}
                        >
                          <MenuItem value={10}>Ympärivuotinen</MenuItem>
                          <MenuItem value={20}>Kesämökki (huhti-loka)</MenuItem>
                          <MenuItem value={30}>Lomamökki (kesä-elo)</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Matka (m)</FormLabel>
                        <TextField
                          id="outlined-basic"
                          //label="Outlined"
                          variant="outlined"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Korjauskerroin (%)</FormLabel>
                        <TextField
                          id="outlined-basic"
                          //label="Outlined"
                          variant="outlined"
                        />
                      </FormControl>
                    </div>
                  )}

                  {propertyType === "farm" && <div>TODO</div>}
                  {propertyType === "field" && <div>TODO</div>}
                  {propertyType === "forest" && <div>TODO</div>}

                  <Typography variant="h6" gutterBottom mt={3} mb={3}>
                    Liikenne
                  </Typography>

                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Onko kiinteistöllä muuta liikennettä
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={propertyTraffic}
                      onChange={handleTrafficRadioChange}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="Ei"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Kyllä"
                      />
                    </RadioGroup>
                  </FormControl>

                  {propertyTraffic === "yes" && <div>TODO</div>}

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormLabel>Matka (m)</FormLabel>
                      <TextField
                        id="outlined-basic"
                        //label="Outlined"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormLabel>Korjauskerroin (%)</FormLabel>
                      <TextField
                        id="outlined-basic"
                        //label="Outlined"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Käyttötarkoitus
                  </FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    value={1}
                    label="Age"
                    //onChange={handleChange}
                  >
                    <MenuItem value={10}>Ympärivuotinen</MenuItem>
                    <MenuItem value={20}>Kesämökki (huhti-loka)</MenuItem>
                    <MenuItem value={30}>Lomamökki (kesä-elo)</MenuItem>
                  </Select>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Maatilan suunta
                  </FormLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select2"
                    value={1}
                    label="maatila"
                    //onChange={handleChange}
                  >
                    <MenuItem value={10}>Kasvinviljely</MenuItem>
                    <MenuItem value={20}>Nautakarja</MenuItem>
                  </Select>
                </form> */}
                <Divider mt={3} />
                <Typography variant="h6" gutterBottom mt={3} mb={3}>
                  Kartta
                </Typography>
                <Mappi propertyGeometries={property?.propertyGeometries} />
              </>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function PropertiesList(props: any) {
  const { currentUser } = useAuth();
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Kiinteistöt
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditUser(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Kiinteistötunnus</TableCell>
              <TableCell>Osoite</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.code} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleShowProperty(row)}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditUser(row)}
                  />{" "}
                  {currentUser?.role === "SUPERADMIN" ? (
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleDelete(row)}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Properties() {
  const { currentUser } = useAuth();

  const dispatch = useAppDispatch();
  const { properties, open, editable } = useAppSelector(
    (state) => state.property
  );

  const handleOpen = () => {
    dispatch(openPropertyForm());
    // dispatch(editPropertyForm());
  };

  const handleShowProperty = (property: any) => {
    dispatch(setProperty(property));
    dispatch(openPropertyForm());
  };

  const handleEditProperty = (property: any) => {
    dispatch(setProperty(property));
    dispatch(openPropertyForm());
    // dispatch(editPropertyForm());
  };

  const handleDelete = (property: any) => {
    // TODO confirm
    dispatch(deleteProperty(property.id));
  };

  useEffect(() => {
    dispatch(fetchProperties(currentUser?.associations[0].id));
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Kiinteistöt" />
      <Typography variant="h3" gutterBottom display="inline">
        Kiinteistöt
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Kiinteistöt</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <PropertyForm handleOpen={handleOpen} />
      ) : (
        <PropertiesList
          rows={properties}
          editable={editable}
          handleShowProperty={handleShowProperty}
          handleEditUser={handleEditProperty}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Properties;
