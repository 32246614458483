import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface File {
  id?: number;
  name: string;
}

export interface FileState {
  file: File | undefined;
  files: File[];
  open: boolean;
  editable: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: FileState = {
  file: undefined,
  files: [],
  open: false,
  editable: false,
  status: "idle",
};

export const fetchFiles = createAsyncThunk(
  "users/fetchFiles",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/files?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

export const uploadFile = createAsyncThunk(
  "files/upload",
  async ({ file, associationId }: { file: any; associationId: any }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("associationId", associationId); // Add associationId to the FormData

      const response = await axios.post(`${baseUrl}/files/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(progress);
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }
);

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    // setUser(state, action) {
    //   state.user = action.payload;
    // },
    // addFile(state, action) {
    //   state.files.push(action.payload);
    // },
    // updateUser(state, action) {
    //   const userId = state.users.findIndex((u) => u.id === action.payload.id);
    //   state.users[userId] = action.payload;
    // },
    // deleteUser(state, action) {
    //   state.users = state.users.filter((u: any) => u.id !== action.payload);
    // },
    // setOpen(state) {
    //   state.open = !state.open;
    // },
    // setEditable(state) {
    //   state.editable = !state.editable;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      state.files = action.payload;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.files.push(action.payload);
    });
  },
});

// export function setUser(user: any) {
//   return async (dispatch: Dispatch) => {
//     dispatch(userSlice.actions.setUser(user));
//   };
// }

// export function createFile(user: any, file: any) {
//   return async (dispatch: Dispatch) => {
//     //console.log("road", road);
//     const response = await axios.post(`${baseUrl}/files/upload`, file);
//     dispatch(fileSlice.actions.addFile(response.data));
//   };
// }

// export function updateUser(id: number, user: any) {
//   return async (dispatch: Dispatch) => {
//     const response = await axios.put(`${baseUrl}/users/${id}`, user);
//     dispatch(userSlice.actions.updateUser(response.data));
//   };
// }

// export function deleteUser(id: number) {
//   return async (dispatch: Dispatch) => {
//     await axios.delete(`${baseUrl}/users/${id}`);
//     dispatch(userSlice.actions.deleteUser(id));
//   };
// }

// export function editUserForm() {
//   return async (dispatch: Dispatch) => {
//     dispatch(userSlice.actions.setEditable());
//   };
// }

// export function openUserForm() {
//   return async (dispatch: Dispatch) => {
//     dispatch(userSlice.actions.setOpen());
//   };
// }

export default fileSlice.reducer;
