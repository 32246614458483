import React from "react";
import styled from "@emotion/styled";

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Spacer = styled.div(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  margin: 20px 0 !important;
  box-shadow: 0 2px 6px 0 rgba(18, 38, 63, 0.05);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;

function Terms() {
  return (
    <Wrapper pt={20} pb={16}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Tiehoitokunta.fi
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Yleiset käyttöehdot ja GDPR
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Alla löydät tärkeimmät ehdot ja tietosuojakäytännöt liittyen
          Tiehoitokunta.fi-palveluun.
        </Typography>
        <Spacer mb={8} />

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} xl={8}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="terms-content"
                id="terms-header"
              >
                <Typography variant="subtitle1">Yleiset käyttöehdot</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  Tiehoitokunta.fi tarjoaa tiekunnille työkalun hallinnan
                  helpottamiseksi. Palvelun käyttö edellyttää rekisteröitymistä,
                  ja käyttäjä sitoutuu noudattamaan Palvelun käyttöehtoja.
                  Käyttöehdot kattavat vastuut, rekisteröitymisen säännöt,
                  maksut sekä muutoksen oikeudet.
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" mt={2}>
                  Tarkemmat ehdot löydät{" "}
                  <a
                    href="https://tiehoitokunta.fi/kayttoehdot/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    käyttöehtosivulta
                  </a>
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="gdpr-content"
                id="gdpr-header"
              >
                <Typography variant="subtitle1">GDPR ja tietosuoja</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  Tiehoitokunta.fi noudattaa EU:n yleistä tietosuoja-asetusta
                  (GDPR) henkilötietojen käsittelyssä. Käsiteltäviin tietoihin
                  kuuluvat nimi, sähköpostiosoite, puhelinnumero ja tiekuntaan
                  liittyvät tiedot. Tietoja säilytetään vain tarvittavan ajan ja
                  niitä suojataan teknisin sekä organisatorisin toimenpitein.
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" mt={2}>
                  Voit tutustua tarkemmin tietosuojakäytäntöihin{" "}
                  <a
                    href="https://tiehoitokunta.fi/tietosuojaseloste/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    tietosuojasivulla
                  </a>
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Terms;
