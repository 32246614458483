import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface Road {
  id?: number;
  name: string;
  municipality: string;
}

export interface RoadState {
  road: Road | undefined;
  roads: Road[];
  open: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: RoadState = {
  road: undefined,
  roads: [],
  open: false,
  status: "idle",
};

export const fetchRoads = createAsyncThunk(
  "roads/fetchRoads",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/roads?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

const roadSlice = createSlice({
  name: "road",
  initialState,
  reducers: {
    setRoad(state, action) {
      state.road = action.payload;
    },
    addRoad(state, action) {
      state.roads.push(action.payload);
    },
    updateRoad(state, action) {
      const roadId = state.roads.findIndex((r) => r.id === action.payload.id);
      state.roads[roadId] = action.payload;
    },
    deleteRoad(state, action) {
      state.roads = state.roads.filter((r: any) => r.id !== action.payload);
    },
    setOpen(state) {
      state.open = !state.open;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoads.fulfilled, (state, action) => {
      state.roads = action.payload;
    });
  },
});

export function setRoad(road: any) {
  return async (dispatch: Dispatch) => {
    dispatch(roadSlice.actions.setRoad(road));
  };
}

export function createRoad(road: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(`${baseUrl}/roads`, road);
    dispatch(roadSlice.actions.addRoad(response.data));
  };
}

export function updateRoad(id: number, road: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(`${baseUrl}/roads/${id}`, road);
    dispatch(roadSlice.actions.updateRoad(response.data));
  };
}

export function deleteRoad(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/roads/${id}`);
    dispatch(roadSlice.actions.deleteRoad(id));
  };
}

export function openRoadForm() {
  return async (dispatch: Dispatch) => {
    dispatch(roadSlice.actions.setOpen());
  };
}

export default roadSlice.reducer;
