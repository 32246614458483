import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface FiscalYear {
  id?: number;
  from: Date;
  to: Date;
}

export interface Accounting {
  fiscalYear: FiscalYear | undefined;
  fiscalYears: FiscalYear[];
  incomeStatement: any;
  assets: any;
  liabilities: any;
  ledger: any;
  journal: any;
  voucher: any;
  vouchers: any;
}

export interface AccountingState {
  fiscalYear: FiscalYear | undefined;
  fiscalYears: FiscalYear[];
  incomeStatement: any;
  assets: any;
  liabilities: any;
  ledger: any;
  journal: any;
  voucher: any;
  vouchers: any[];
  open: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: AccountingState = {
  fiscalYear: undefined,
  fiscalYears: [],
  incomeStatement: [],
  assets: [],
  liabilities: [],
  ledger: [],
  journal: [],
  voucher: undefined,
  vouchers: [],
  open: false,
  status: "idle",
};

export const fetchFiscalYears = createAsyncThunk(
  "accounting/fetchFiscalYears",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/accounting/fiscal-years?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchFinancialStatements = createAsyncThunk(
  "accounting/fetchFinancialStatements",
  async (params: any) => {
    const { associationId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/financial-statement?associationId=${associationId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchLedger = createAsyncThunk(
  "accounting/fetchLedger",
  async (params: any) => {
    const { associationId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/ledger?associationId=${associationId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchJournal = createAsyncThunk(
  "accounting/fetchJournal",
  async (params: any) => {
    const { associationId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/journal?associationId=${associationId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchVouchers = createAsyncThunk(
  "accounting/fetchVouchers",
  async (params: any) => {
    const { associationId } = params;
    const response = await axios(
      `${baseUrl}/accounting/vouchers?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

// from 2020-01-01
// to 2024-12-31

const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    // setUser(state, action) {
    //   state.user = action.payload;
    // },
    setFiscalYear(state, action) {
      state.fiscalYear = action.payload;
    },
    addFiscalYears(state, action) {
      state.fiscalYears.push(action.payload);
    },
    updateFiscalYear(state, action) {
      const fiscalYearId = state.fiscalYears.findIndex(
        (r) => r.id === action.payload.id
      );
      state.fiscalYears[fiscalYearId] = action.payload;
    },
    deleteFiscalYear(state, action) {
      state.fiscalYears = state.fiscalYears.filter(
        (f: any) => f.id !== action.payload
      );
    },
    addVoucher(state, action) {
      state.vouchers.push(action.payload);
    },
    updateVoucher(state, action) {
      const voucherId = state.vouchers.findIndex(
        (u: any) => u.id === action.payload.id
      );
      state.vouchers[voucherId] = action.payload;
    },
    deleteVoucher(state, action) {
      state.vouchers = state.vouchers.filter(
        (u: any) => u.id !== action.payload
      );
    },
    setOpen(state) {
      state.open = !state.open;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiscalYears.fulfilled, (state, action) => {
      state.fiscalYears = action.payload;
    });
    builder.addCase(fetchFinancialStatements.fulfilled, (state, action) => {
      state.incomeStatement = action.payload.incomeStatement;
      state.assets = action.payload.assets;
      state.liabilities = action.payload.liabilities;
    });
    builder.addCase(fetchLedger.fulfilled, (state, action) => {
      state.ledger = action.payload;
    });
    builder.addCase(fetchJournal.fulfilled, (state, action) => {
      state.journal = action.payload;
    });
    builder.addCase(fetchVouchers.fulfilled, (state, action) => {
      state.vouchers = action.payload;
    });
  },
});

export function setFiscalYear(fiscalYear: FiscalYear) {
  return async (dispatch: Dispatch) => {
    dispatch(accountingSlice.actions.setFiscalYear(fiscalYear));
  };
}

export function createFiscalYear(
  fisCalYear: FiscalYear,
  associationId: number
) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(
      `${baseUrl}/accounting/fiscal-years?associationId=${associationId}`,
      { ...fisCalYear, active: true }
    );
    dispatch(accountingSlice.actions.addFiscalYears(response.data));
  };
}

export function updateFiscalYear(id: number, fiscalYear: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(
      `${baseUrl}/accounting/fiscal-years/${id}`,
      fiscalYear
    );
    dispatch(accountingSlice.actions.updateFiscalYear(response.data));
  };
}

export function deleteFiscalYear(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/accounting/fiscal-years/${id}`);
    dispatch(accountingSlice.actions.deleteFiscalYear(id));
  };
}

export function createVoucher(voucher: any, associationId: number) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(
      `${baseUrl}/accounting/vouchers?associationId=${associationId}`,
      voucher
    );
    dispatch(accountingSlice.actions.addVoucher(response.data));
  };
}

export function updateVoucher(id: number, voucher: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(
      `${baseUrl}/accounting/vouchers/${id}`,
      voucher
    );
    dispatch(accountingSlice.actions.updateVoucher(response.data));
  };
}

export function deleteVoucher(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/accounting/vouchers/${id}`);
    dispatch(accountingSlice.actions.deleteVoucher(id));
  };
}

export function openFiscalYearsForm() {
  return async (dispatch: Dispatch) => {
    dispatch(accountingSlice.actions.setOpen());
  };
}

export default accountingSlice.reducer;
