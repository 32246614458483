import React, { useState } from "react";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowDown, ArrowUp } from "react-feather";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function renderTableRows(
  data: any,
  level = 0,
  expandedRows: any,
  handleToggleExpand: any
) {
  return data.map((item: any) => (
    <React.Fragment key={item.id}>
      {/* Main data row with expandable icon if accounts exist */}
      <TableRow>
        <TableCell style={{ paddingLeft: `${level * 20}px` }}>
          {item.accounts ? (
            <IconButton
              size="small"
              onClick={() => handleToggleExpand(item.id)}
            >
              {expandedRows.includes(item.id) ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : (
            <span style={{ paddingLeft: "20px" }} /> // Adjust alignment for non-expandable rows
          )}
          {item.name}
        </TableCell>
        <TableCell align="right">
          {item.value
            ? new Intl.NumberFormat("fi-FI", {
                style: "currency",
                currency: "EUR",
              }).format(item.value)
            : "-"}
        </TableCell>
      </TableRow>

      {/* Conditionally render nested accounts if expanded */}
      {expandedRows.includes(item.id) &&
        item.accounts?.map((account: any) => (
          <TableRow key={account.code}>
            <TableCell style={{ paddingLeft: `${(level + 1) * 20}px` }}>
              {account.name}
            </TableCell>
            <TableCell align="right">
              {account.value
                ? new Intl.NumberFormat("fi-FI", {
                    style: "currency",
                    currency: "EUR",
                  }).format(account.value)
                : "-"}
            </TableCell>
          </TableRow>
        ))}

      {/* Recursively render children if they exist */}
      {item.children &&
        renderTableRows(
          item.children,
          level + 1,
          expandedRows,
          handleToggleExpand
        )}
    </React.Fragment>
  ));
}

function nestData(data: any) {
  const map: any = {};
  const result: any[] = [];

  data.forEach((item: any) => (map[item.id] = { ...item, children: [] }));
  data.forEach((item: any) => {
    if (item.parentId) {
      map[item.parentId]?.children.push(map[item.id]);
    } else {
      result.push(map[item.id]);
    }
  });

  return result;
}

function IncomeStatement(props: any) {
  const [expandedRows, setExpandedRows] = useState([]);

  // Toggle expand/collapse state for each row with accounts
  const handleToggleExpand = (id: any) => {
    setExpandedRows((prevExpandedRows: any) =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter((rowId: any) => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };
  const nestedData = nestData(props.rows);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nimi</TableCell>
            <TableCell align="right">Määrä</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(nestedData, 0, expandedRows, handleToggleExpand)}
        </TableBody>
      </Table>
    </TableContainer>

    // <Table style={{ marginTop: "20px" }} size={"small"}>
    //   <TableHead>
    //     <TableRow>
    //       <TableCell></TableCell>
    //       <TableCell>ID</TableCell>
    //       <TableCell>Nimi</TableCell>
    //       <TableCell>Value</TableCell>
    //     </TableRow>
    //   </TableHead>
    //   <TableBody>
    //     {(props.rows || []).map((row: any) => (
    //       <>
    //         <TableRow key={row.id} hover>
    //           <TableCell>
    //             {row.accounts ? (
    //               <IconButton
    //                 aria-label="expand row"
    //                 size="small"
    //                 onClick={() => handleSelectedRow(row)}
    //               >
    //                 {open ? <ArrowUp /> : <ArrowDown />}
    //               </IconButton>
    //             ) : null}
    //           </TableCell>
    //           <TableCell>{row.id}</TableCell>
    //           <TableCell>{row.name}</TableCell>
    //           <TableCell>
    //             {new Intl.NumberFormat("fi-FI", {
    //               style: "currency",
    //               currency: "EUR",
    //             }).format(row.value)}
    //           </TableCell>
    //         </TableRow>
    //         {row.accounts
    //           ? row.accounts.map((account: any) => (
    //               <TableRow>
    //                 <TableCell></TableCell>
    //                 <TableCell>{account.code}</TableCell>
    //                 <TableCell>{account.name}</TableCell>
    //                 <TableCell>
    //                   {account.value
    //                     ? new Intl.NumberFormat("fi-FI", {
    //                         style: "currency",
    //                         currency: "EUR",
    //                       }).format(row.value)
    //                     : null}
    //                 </TableCell>
    //               </TableRow>
    //             ))
    //           : null}
    //       </>
    //     ))}
    //   </TableBody>
    // </Table>
  );
}

export default IncomeStatement;
