import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField as MuiTextField,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import association, {
  createAssociation,
  deleteAssociation,
  fetchAssociations,
  openAssociationForm,
  setAssociation,
  updateAssociation,
} from "../../redux/slices/association";
import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function RoadForm(props: any) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const { association } = useAppSelector((state) => state.association);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (association && association.id !== undefined) {
        dispatch(updateAssociation(association.id, values));
      } else {
        dispatch(createAssociation(values));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      dispatch(openAssociationForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        association
          ? {
              name: association.name,
              meetingDate: association.meetingDate,
              billDueDate: association.billDueDate,
              basePay: association.basePay,
              reminderFee: association.reminderFee,
              road: currentUser?.associations[0].road.id,
              foreclosureFee: association.foreclosureFee,
              unitPay: association.unitPay,
            }
          : {
              name: "",
              meetingDate: "",
              billDueDate: "",
              basePay: "",
              reminderFree: "",
              road: currentUser?.associations[0].road.id,
              foreclosureFee: "",
              unitPay: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Hoitokunta
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="name"
                    label="Nimi"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  {/* @ts-ignore */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Kokous Pvm"
                      value={values.meetingDate}
                      //onChange={handleChange}
                      onChange={(newValue) => {
                        setFieldValue("meetingDate", newValue); // This will update Formik's value
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="meetingDate"
                          fullWidth
                          //onBlur={handleBlur}
                          error={Boolean(
                            touched.meetingDate && errors.meetingDate
                          )}
                          //helperText={touched.meetingDate && errors.meetingDate}
                          my={2}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                  {/* @ts-ignore */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Laskun Eräpäivä"
                      value={values.billDueDate}
                      onChange={(newValue) => {
                        setFieldValue("billDueDate", newValue); // This will update Formik's value
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="billDueDate"
                          fullWidth
                          error={Boolean(
                            touched.billDueDate && errors.billDueDate
                          )}
                          //helperText={touched.billDueDate && errors.billDueDate}
                          onBlur={handleBlur}
                          my={2}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {/* <TextField
                    name="billDueDate"
                    label="billDueDate"
                    value={values.billDueDate}
                    error={Boolean(touched.billDueDate && errors.billDueDate)}
                    fullWidth
                    helperText={touched.billDueDate && errors.billDueDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> */}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="basePay"
                    label="Perusmaksu"
                    value={values.basePay}
                    error={Boolean(touched.basePay && errors.basePay)}
                    fullWidth
                    helperText={touched.basePay && errors.basePay}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="reminderFee"
                    label="Muistutusmaksu"
                    value={values.reminderFee}
                    error={Boolean(touched.reminderFee && errors.reminderFee)}
                    fullWidth
                    helperText={touched.reminderFee && errors.reminderFee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="foreclosureFee"
                    label="Ulosottomaksu"
                    value={values.foreclosureFee}
                    error={Boolean(
                      touched.foreclosureFee && errors.foreclosureFee
                    )}
                    fullWidth
                    helperText={touched.foreclosureFee && errors.foreclosureFee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="unitPay"
                    label="Yksikkömaksu"
                    value={values.unitPay}
                    error={Boolean(touched.unitPay && errors.unitPay)}
                    fullWidth
                    helperText={touched.unitPay && errors.unitPay}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function RoadList(props: any) {
  const { currentUser } = useAuth();
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Hoitokunta
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditAssociation(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Kunta</TableCell>
              <TableCell>Maakunta</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.id} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.municipality}</TableCell>
                <TableCell>{row.county}</TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => console.log("show")}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditAssociation(row)}
                  />{" "}
                  {currentUser?.role === "SUPERADMIN" ? (
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleDelete(row)}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Accosiation() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { associations, open } = useAppSelector((state) => state.association);

  const handleOpen = () => {
    dispatch(openAssociationForm());
  };

  const handleEditAssociation = (association: any) => {
    dispatch(setAssociation(association));
    dispatch(openAssociationForm());
  };

  const handleDelete = (association: any) => {
    // TODO confirm
    dispatch(deleteAssociation(association.id));
  };

  useEffect(() => {
    dispatch(fetchAssociations(currentUser?.associations[0].id));
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Tiet" />
      <Typography variant="h3" gutterBottom display="inline">
        Hoitokunta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Hoitokunta</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <RoadForm handleOpen={handleOpen} />
      ) : (
        <RoadList
          rows={associations}
          handleEditAssociation={handleEditAssociation}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Accosiation;
