import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function SimpleAccordion() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Ohjeet
        </Typography>
        <Typography variant="body2" gutterBottom>
          Tällä sivulla on koottu ohjeita ohjelman sujuvaan käyttöön.
        </Typography>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Hoitokunta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Hoitokunnan tehtävänä on vastata yhdistyksen toiminnasta ja
                teiden kunnossapidosta. Hoitokunnan jäsenet voivat käyttää
                ohjelmiston työkaluja esimerkiksi kokouspäätösten kirjaamiseen,
                yksiköinnin päivittämiseen ja tärkeiden viestien lähettämiseen
                osakkaille. Ohjelmiston kautta voi myös tallentaa tiedostoja,
                kuten pöytäkirjoja ja laskelmia.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Osakkaat</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Osakkaat ovat yhdistyksen jäsenet, jotka omistavat kiinteistöjä
                yhdistyksen alueella. Ohjelmistosta voit tarkistaa
                osakasluettelon, päivittää osakkaiden tietoja ja lisätä uusia
                osakkaita. Osakkaille voidaan lähettää viestejä suoraan
                ohjelmiston viestityökalulla. Osakasnäkymästä näet myös
                osakkaiden yksiköinnit ja maksutiedot.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Tiet</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Tiet-moduulissa hallinnoidaan yhdistyksen teitä. Voit lisätä ja
                muokata tiekohtaisia tietoja, kuten tien nimi, pituus ja
                kunnossapitotaso. Tämän kautta voidaan myös luoda kunnossapidon
                kustannusarviot ja jakaa kulut osakkaille.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kiinteistöt</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Kiinteistöjen hallintatyökalulla hallinnoidaan yhdistyksen
                alueen kiinteistöjä. Tähän sisältyy kiinteistöjen tiedot, kuten
                osoite, pinta-ala ja osakkaiden omistustiedot. Voit liittää
                kiinteistön tiettyyn tieosaan ja päivittää kiinteistön
                yksiköintiarvot.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Talous</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Talous ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Yksiköinti</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yksiköinti-moduulilla hallitaan teiden käytön perusteella
                määriteltäviä yksiköintiarvoja. Voit luoda uusia yksiköintejä,
                muokata olemassa olevia ja tarkastella yksiköintijakaumaa. Tämä
                toimii pohjana kustannusten jaolle osakkaiden kesken.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Viestit</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Viestityökalu mahdollistaa nopean viestinnän osakkaiden ja
                hoitokunnan välillä. Voit lähettää ilmoituksia, muistutuksia tai
                kokouskutsuja suoraan ohjelmiston kautta. Viestit voidaan
                lähettää myös ryhmille, kuten kaikille osakkaille tai tietyn
                tien osakkaille.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kokous</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Kokousmoduulilla hallinnoidaan yhdistyksen kokouksia. Voit luoda
                kokouskutsut, laatia esityslistat ja kirjata kokouksen
                pöytäkirjat. Pöytäkirjoihin voi lisätä päätökset ja
                yksityiskohdat, joita voidaan myöhemmin tarkastella tiedostojen
                kautta.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Tiedostot</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Tiedostot-moduulissa voit tallentaa ja hallinnoida yhdistyksen
                dokumentteja, kuten pöytäkirjoja, karttoja ja laskelmia. Voit
                luoda kansioita ja järjestää tiedostot helposti löydettävään
                muotoon. Tämän avulla varmistat, että kaikki tärkeät tiedot ovat
                saatavilla yhdessä paikassa.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kartta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Kartta-moduuli tarjoaa visuaalisen näkymän yhdistyksen
                alueeseen. Voit tarkastella teiden ja kiinteistöjen sijainteja,
                lisätä merkintöjä ja jakaa karttanäkymän osakkaiden kanssa.
                Kartan avulla voidaan suunnitella esimerkiksi kunnossapitotyöt
                ja tarkastella yksiköinnin vaikutuksia maantieteellisesti.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Yhteys</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Voit ottaa yhteyttä teknisissä ja yleisissä kysymyksissä
                ylläpitoon alla olevan linkin kautta:
              </Typography>
              <Link component={NavLink} to="/contact">
                Yhteys
              </Link>
            </AccordionDetails>
          </Accordion>
        </div>
      </CardContent>
    </Card>
  );
}

function Docs() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Ohjeet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Ohjeet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <SimpleAccordion />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Docs;
