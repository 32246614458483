import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Breadcrumbs,
  Divider,
  TextField,
  Typography,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import { createConversation } from "../../redux/slices/conversation";

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(6)} 0`,
}));
const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
});
const ButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}));
const SubmitButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1.25),
}));

// Types
interface ContactFormProps {
  message: string;
  onMessageChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
  onReset: () => void;
}

// Contact Form Component
const ContactForm: React.FC<ContactFormProps> = ({
  message,
  onMessageChange,
  onSubmit,
  onReset,
}) => (
  <FormContainer onSubmit={onSubmit}>
    <TextField
      required
      id="contact-message"
      label="Viesti"
      multiline
      rows={10}
      value={message}
      onChange={(e) => onMessageChange(e.target.value)}
    />
    <ButtonContainer>
      <SubmitButton variant="contained" color="primary" type="submit">
        Lähetä
      </SubmitButton>
      <Button variant="outlined" onClick={onReset}>
        Peruuta
      </Button>
    </ButtonContainer>
  </FormContainer>
);

// Page Header Component
const PageHeader: React.FC = () => (
  <>
    <Typography variant="h3" gutterBottom>
      Yhteys
    </Typography>
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={NavLink} to="/">
        Etusivu
      </Link>
      <Typography>Yhteys</Typography>
    </Breadcrumbs>
    <StyledDivider />
  </>
);

// Main Contact Component
const Contact: React.FC = () => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      dispatch(createConversation(1, message));
      setMessage("");
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => setMessage("");
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <>
      <Helmet title="Yhteys" />
      <PageHeader />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Voit ottaa yhteyttä ylläpitoon lähettämällä viestin tämän
                kautta.
              </Typography>
              <ContactForm
                message={message}
                onMessageChange={setMessage}
                onSubmit={handleSubmit}
                onReset={handleReset}
              />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Viesti lähetetty onnistuneesti!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Contact;
